import React from "react";
import "./Statistics.css";

function Statistics(props) {
	return (
		<div className="Statistics">
			<h1>Statistics</h1>
			<h2>(Under Construction)</h2>
		</div>
	);
}
export default Statistics;
