import "./MissingPage.css";
function missingPage() {
	return (
		<>
			<div className="MissingPage">
				<h1>404</h1>
				<h2>(Looks like you got lost in the forest!)</h2>
			</div>
		</>
	);
}

export default missingPage;
