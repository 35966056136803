import React from "react";
import "./Store.css";

function Store(props) {
  return (
    <div className="Store">
      <h1>Store</h1>
      <h2>(Under Construction)</h2>
    </div>
  );
}
export default Store;
