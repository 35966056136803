import React from "react";
import "./Archive.css";

function Archive(props) {
	return (
		<div className="Archive">
			<h1>Archive</h1>
			<h2>(Under Construction)</h2>
		</div>
	);
}
export default Archive;
